export default {
  SYSTEM: {
    translation: {
      ruz: {
        title: 'РУЗ 2.0',
        reportTitle: 'Занятость по проектам для РУЗ 2.0',
        loadProjectTitle: 'Фактическая загрузка на проекте в соответствии с РУЗ 2.0, %',
        loadProgramTitle: 'Фактическая загрузка на программе в соответствии с РУЗ 2.0, %',
        loadPortfolioTitle: 'Фактическая загрузка на портфеле в соответствии с РУЗ 2.0, %',
      },
      userManagement: {
        segments: 'Сегменты',
        currentSegments: 'Текущие сегменты',
        changeSegments: 'Изменить сегменты',
      },
      authRequest: {
        loginDesc: 'Tребуется ввести адрес корпоративной электронной почты',
      },
      accessOrganizationView: {
        dzo: 'ДЗО',
      },
      reportPage: {
        budget: {
          isShowAllVersionsWithR12: 'Все версии карточек с кодом R12',
        }
      },
    },
  },
  PROJECT: {
    translation: {
      'budgetIntegrationButtonText': 'Получить бюджет из Oracle R12',
      dashboard: {
        segmentUtoch: 'Сегмент_уточ (Бюджетодержатель)',
      },
      expressCommitteeWeb: {
        draftDecision: {
          name: 'Пункт проекта решения'
        }
      },
      ppmRequestWeb: {
        employees: {
          __sectionName: 'Команда',
          roleId: 'Роль',
          user: 'Сотрудник',
        },
      },
      ppmRequestKpiWeb: {
        name: 'Наименование КПЭ',
        plan: 'План по КПЭ',
        fact: 'Факт по КПЭ',
        typeId: 'Тип КПЭ',
        statusId: 'Статус',
        year: 'Год',
        weight: 'Вес КПЭ внутри года, %',
        methodCalc: 'Метод расчета',
        unitType: 'Единица измерения',
        result: 'Результат по итогам выполнения, %',
        dateResult: 'Плановая дата достижения',
        dateEndFact: 'Фактическая дата достижения',
        resultComment: 'Описание достигнутого результата',
      },
      workDataRequest: {
        controlPoint: 'Контрольное событие',
        controlPointTypeName: 'Тип контрольного события',
        controlPointSubTypeName: 'Подтип подтип контрольного события',
      },
      employeeDataCreate: {
        cfoId: 'ЦФО/МВЗ',
        isFotCapitalize: 'ФОТ сотрудника капитализируется в данном проекте',
        functionCode: 'Код функции',
      },
      projectVersionData: {
        shortName: 'Краткое наименование проекта',
        stateFundingR12code: '$t(projectVersion.budget.stateFundingR12code)',
        costsLocationId: 'Место возникновения затрат (МВЗ)',
        beneficiaryCfoId: 'ЦФО-выгодоприобретателя',
        territories: {
          territoryId: 'Территория',
          percent: '% распределения',
        },
        verticals: {
          verticalId: 'Вертикаль',
          percent: '% распределения',
        },
      },
      projectBudgetSourceWeb: {
        r12Code: 'Код проекта в R12',
        total: 'Бюджет проекта, тыс. руб. (ОКВ)',
        okbFreeFunds: 'Незаконтрактованный остаток бюджета (ОКВ), тыс. руб.',
        ddsFreeFunds: 'Незаконтрактованный остаток бюджета (ДДС), тыс. руб. (с НДС)',
        nzs: 'НЗС на конец периода',
        budgetSegment: 'Сегмент_уточ (Бюджетодержатель)',
      },
      budgetPredictionOrderWeb: {
        id: 'Идентификтор заказа',
        num: 'Номер заказа',
        r12code: 'Код проекта в R12',
        workName: 'Блок работ',
        budgetIndicatorId: 'Показатель',
        typeId: 'Заключение заказа/перенос затрат',
        transferItem: 'Код и название проекта/статьи затрат с которых необходим перенос затрат',
        investCategoryId: 'Категория инвестиций',
        docNumber: 'Системный номер',
        subjectDescription: 'Описание предмета заказа',
        contractor: 'Контрагент',
        isContractSystemNumber: 'Наличие рамочного договора',
        contractSystemNumber: 'Системный номер рамочного договора',
        isCompetitiveProcedures: 'Потребность в проведении закупочных процедур, включая конкурентные и неконкурентные',
        initiationPeriod: 'Период инициации закупочной процедуры',
        competitiveProceduresDuration: 'Длительность конкурсных процедур, дни',
        signingDate: 'Срок подписания заказа',
        completionDate: 'Срок поставки/выполнения работ',
        dueDate: 'Срок оплаты',
        transferOkbDate: 'Месяц переноса ОКВ',
        transferDdsDate: 'Месяц переноса ДДС',
        taxRate: 'Ставка НДС, %',
        okb: 'Сумма заказа ОКВ, тыс. руб. без НДС',
        dds: 'Сумма заказа ДДС, тыс. руб. с НДС',
        ddsNoNds: 'Сумма заказа ДДС, тыс. руб. без НДС',
        prepay: 'Аванс, % от суммы заказа при наличии',
        prepayDate: 'Месяц перечисления аванса',
        comment: 'Комментарий',
        orderActionId: 'Тип оплаты',
      },
      projectVersion: {
        segment: 'Сегмент заказчика',
        segmentExecutor: 'Сегмент исполнителя',
        manager: '$t(Input manager title)',
        isStateFunding: 'Гос. контракт',
        isDppgfSupport: 'Проект с сопровождением ДППГФ',
        territoryPsr: 'Территория',
        category: 'Категория проекта',
        isBtiPsr: 'ПСР',
        isModernizationNetwork: 'Модернизация энергетики сети',
        budget: {
          stateFundingR12code: 'Код программы проекта',
          isNoStateFundingR12code: 'Нет кода программы из R12',
          totalDdsNoNds: 'Суммарный Бюджет проекта, $t(SUM_TITLE), без НДС (ДДС)',
          totalDdsNoNdsMobile: 'Суммарный Бюджет, $t(SUM_TITLE), без НДС (ДДС)',
          oibda: {
            prediction: 'Прогноз, тыс. руб.',
          },
          sfpFinIndicator: {
            programCode: 'Код программы',
            limitMargin: 'Прибыль от цены, руб: предел (ГК/НПА)',
            limitMarginToExpense: 'Прибыль от себестоимости, руб: предел (ГК/НПА)',
            limitMarginToExpenseRate: '% прибыли от себестоимости: предел (ГК/НПА)',
            limitOwnExpenseRate: '% исполнения собственными силами: предел (ГК/НПА)',
            limitMarginToPriceRate: '% прибыли от цены: предел (ГК/НПА)',
            planMargin: 'Прибыль от цены, руб: план (МРЗ)',
            planMarginToExpense: 'Прибыль от себестоимости, руб: план (МРЗ)',
            planMarginToExpenseRate: '% прибыли от себестоимости: план (МРЗ)',
            planOwnExpenseRate: '% исполнения собственными силами: план (МРЗ)',
            planMarginToPriceRate: '% прибыли от цены: план (МРЗ)',
            factMargin: 'Прибыль от цены, руб: факт',
            factMarginToExpense: 'Прибыль от себестоимости, руб: факт',
            factMarginToExpenseRate: '% прибыли от себестоимости: факт',
            factOwnExpenseRate: '% исполнения собственными силами: факт',
            factMarginToPriceRate: '% прибыли от цены: факт',
          }
        },
        isCommunicationChannels: 'Конфиденциальная информация',
        investDirection: 'Инвестиционное направление',
        investProgram: 'Инвестиционная программа',
        isWithoutProcurement: 'Проект без закупок',
        projectDirection: 'Направление проекта',
        psrPortalUrl: 'Ссылка на портал ПСР-задач',
      },
      ppmRequestBudgetController: {
        postListBudget: {
          dataList: {
            name: '$t(projectBudgetSourceWeb.name)',
            total: '$t(projectBudgetSourceWeb.total)',
            r12Code: '$t(projectBudgetSourceWeb.r12Code)',
            cfoId: '$t(projectBudgetSourceWeb.cfoId)',
          }
        }
      },
      projectData: {
        jiraCode: '$t(Input jiraCode title)',
        confluenceCode: 'Код в Confluence',
      },
      registerLdapRequest: {
        login: 'Имя пользователя',
        password: 'Пароль',
        roleList: 'Права доступа',
      },
      businessCaseWeb: {
        beneficiarySegment: 'Сегмент бенефициара',
        strategicInitiative: 'Стратегическая инициатива',
        isOpexIndicator: 'Влияние показателя на статьи OPEX',
        opex: {
          businessCaseId: 'Бизнес процесс',
          shppId: 'ШПП',
          r12Id: 'Код статьи в R12',
        },
      },
      budgetIncomeIndicatorData: {
        __name: 'Доходные показатели',
        marginTypeId: 'Тип маржи',
        margin: 'Маржа (GM2)',
        marginZni: 'Маржа GM2 ЗнИ',
        incomeIndicators: {
          __name: 'Источники дохода',
          typeId: 'Тип доходного показателя',
        }
      },
      menu: {
        initReportCharter: 'Устав проекта'
      },
    }
  },
  PROGRAM: {
    translation: {
      menu: {
        dashboard: 'Дашборд программы',
        initReportCharter: 'Устав программы'
      },
      dashboardComponent: {
        menu: {
          structure: 'Структура программы',
          indicators: 'Общие показатели',
          budget: 'ФЭП',
          gantt: 'График по компонентам',
          status: 'Статус по компонентам',
        },
      },
      projectVersionData: {
        shortName: 'Краткое наименование программы',
      },
      projectBudgetSourceWeb: {
        total: 'Бюджет программы проекта, тыс. руб. (ОКВ)',
      },
      employeeDataCreate: {
        isFotCapitalize: 'ФОТ сотрудника капитализируется в данной программе',
      },
      projectVersion: {
        category: 'Категория программы',
        isWithoutProcurement: 'Программа без закупок',
        budget: {
          totalDdsNoNds: 'Суммарный бюджет программы, $t(SUM_TITLE), без НДС (ДДС)',
          totalDdsNoNdsMobile: 'Суммарный Бюджет, $t(SUM_TITLE), без НДС (ДДС)',
        }
      },
    }
  },
  PORTFOLIO: {
    translation: {
      menu: {
        dashboard: 'Дашборд портфеля',
        initReportCharter: 'Устав портфеля'
      },
      dashboardComponent: {
        menu: {
          structure: 'Структура портфеля',
          indicators: 'Общие показатели',
          budget: 'ФЭП',
          gantt: 'График по компонентам',
          status: 'Статус по компонентам',
        },
      },
      projectVersionData: {
        shortName: 'Краткое наименование портфеля',
      },
      projectBudgetSourceWeb: {
        total: 'Бюджет портфеля проекта, тыс. руб. (ОКВ)',
      },
      employeeDataCreate: {
        isFotCapitalize: 'ФОТ сотрудника капитализируется в данном портфеле',
      },
      projectVersion: {
        budget: {
          totalDdsNoNds: 'Суммарный бюджет портфеля, $t(SUM_TITLE), без НДС (ДДС)',
          totalDdsNoNdsMobile: 'Суммарный Бюджет, $t(SUM_TITLE), без НДС (ДДС)',
        }
      }
    }
  },
  PPM_REQUEST: {
    translation: {
      projectBudgetSourceWeb: {
        total: 'Бюджет проекта, тыс. руб.',
      },
    },
  },
}